import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import BlogBanner from "../components/BlogBanner";
import { SafeHtmlParser } from "../components/SafeHtmlParser";
import Hero from "../components/Header1";
import Header2 from "../components/Header2";
import CustomerTestimonials from "../components/CustomerTestimonials";
import CardLayout from "../components/CardLayout";
import CardLayout2 from "../components/CardLayout2";
import { GatsbyImage } from "gatsby-plugin-image";
import BlogListing from "../components/BlogListing";
import ContactLayout from "../components/ContactLayout";
import ContactLayout2 from "../components/ContactLayout2";
import SectionLayout from "../components/SectionLayout";
import SectionLayout5 from "../components/SectionLayout5";
import SectionLayout2 from "../components/SectionLayout2";
import SectionLayout3 from "../components/SectionLayout3";
import SectionLayout4 from "../components/SectionLayout4";
import OurTeam from "../components/OurTeam";
import CtaBanner from "../components/CtaBanner";
import BlogListingAll from "../components/BlogListingAll";
import RecommendedBlogs from "../components/RecommandedBlogs";
import CareerListing from "../components/CareerListing";

const BlogPostTemplate = ({
	data: { post, site, allWpPost, allWpTeam, reviews },
}) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const postUrl = `${site.siteMetadata.siteUrl}/blog/${post.slug}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/blog/${post.slug}`,
					id: `${siteUrl}/blog/${post.slug}`,
				},
			},
		],
	};

	const componentMap = {
		Post_Pagefieldsgroup_Components_Header1: (data) => (
			<Hero
				title={data?.heading}
				description={data?.description}
				button={data?.link}
				logos={data?.logos}
				image={data?.image?.localFile?.childImageSharp?.gatsbyImageData}
			/>
		),
		Post_Pagefieldsgroup_Components_Header2: (data) => (
			<Header2
				heading={data?.heading}
				button={data?.link}
				overlayColour={data?.overlayColor}
				backgroundImage={
					data?.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		Post_Pagefieldsgroup_Components_CardsLayout: (data) => (
			<CardLayout
				heading={data?.heading}
				button={data?.link}
				services={data?.services}
			/>
		),
		Post_Pagefieldsgroup_Components_CardsLayout2: (data) => (
			<CardLayout2
				heading={data?.heading}
				button={data?.link}
				content={data?.description}
				items={data?.items}
				learnMoreLink={data?.learnMoreLink}
			/>
		),
		Post_Pagefieldsgroup_Components_CustomerTestimonial: (data) => (
			<CustomerTestimonials
				heading={data?.heading}
				content={data?.description}
				overlayColor={data?.overlayColor}
				testimonials={reviews?.reviews}
				bgImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		Post_Pagefieldsgroup_Components_Image: (data) => (
			<GatsbyImage
				image={data?.image?.localFile?.childImageSharp?.gatsbyImageData}
				alt={data?.image?.altText}
				className="w-100 h-auto"
			/>
		),
		Post_Pagefieldsgroup_Components_ContactLayout: (data) => (
			<ContactLayout
				heading={data?.heading}
				content={data?.description}
				items={data?.contactItem}
			/>
		),
		Post_Pagefieldsgroup_Components_ContactLayout2: (data) => (
			<ContactLayout2 heading={data?.heading} content={data?.description} />
		),
		Post_Pagefieldsgroup_Components_SectionLayout: (data) => (
			<SectionLayout heading={data?.heading} content={data?.description} />
		),
		Post_Pagefieldsgroup_Components_SectionLayout2: (data) => (
			<SectionLayout2
				heading={data?.heading}
				content={data?.description}
				overlayColor={data?.overlayColor}
				bgImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				backgroundColor={data?.backgroundColor}
				button={data?.link}
			/>
		),
		Post_Pagefieldsgroup_Components_SectionLayout3: (data) => (
			<SectionLayout3
				tagline={data?.tagline}
				heading={data?.heading}
				content={data?.description}
				button={data?.link}
			/>
		),
		Post_Pagefieldsgroup_Components_SectionLayout4: (data) => (
			<SectionLayout4
				heading={data?.heading}
				content={data?.description}
				overlayColor={data?.overlayColor}
				items={data?.items}
				bgImage={
					data?.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		Post_Pagefieldsgroup_Components_SectionLayout5: (data) => (
			<SectionLayout5 teams={allWpTeam.nodes} note={data.note} />
		),
		Post_Pagefieldsgroup_Components_CtaBanner: (data) => (
			<CtaBanner
				heading={data?.heading}
				button={data?.link}
				content={data?.description}
				backgroundColor={data?.backgroundColor}
				image={data?.icon?.localFile?.childImageSharp?.gatsbyImageData}
			/>
		),
		Post_Pagefieldsgroup_Components_BlogListing: (data) => (
			<BlogListing
				tagline={data?.tagline}
				heading={data?.heading}
				content={data?.description}
				posts={allWpPost?.nodes}
			/>
		),
		Post_Pagefieldsgroup_Components_RelatedBlogs: (data) => (
			<RecommendedBlogs
				tagline={data?.tagline}
				heading={data?.heading}
				content={data?.description}
				button={data?.link}
				posts={data?.blogs}
			/>
		),
		Post_Pagefieldsgroup_Components_OurTeam: (data) => (
			<OurTeam
				teams={allWpTeam.nodes}
				heading={data.heading}
				hiringDescription={data?.hiringDescription}
				hiringTitle={data?.hiringTitle}
				hiringLink={data?.hiringLink}
			/>
		),
		Post_Pagefieldsgroup_Components_BlogListingAll: (data) => (
			<BlogListingAll
				tagline={data?.tagline}
				heading={data?.heading}
				content={data?.description}
				posts={allWpPost?.nodes}
			/>
		),
		Post_Pagefieldsgroup_Components_CareerListing: (data) => (
			<CareerListing
        		positions={data.positions}
			/>
		),
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post.seoFields?.metaTitle}
				description={post.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/blog/${post.slug}`,
					title: `${post.seoFields?.openGraphTitle}`,
					description: `${post.seoFields?.openGraphDescription}`,
					images: [
						{
							url: `${post.seoFields?.image?.localFile.publicURL}`,
							width: `${post.seoFields?.image?.localFile.childImageSharp.original.width}`,
							height: `${post.seoFields?.image?.localFile.childImageSharp.original.height}`,
							alt: `${post.seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<BlogBanner
					title={post.title}
					postFields={post.blogField}
					postUrl={postUrl}
					date={post.date}
					backgroundImage={
						post.bannerBackgroundImage?.localFile?.childImageSharp
							?.gatsbyImageData
					}
					author={post.author.node}
				/>
				<section className="py-5 py-md-8 bg-primary">
					<Container>
						<Row className="gx-6">
							<Col lg={12} className="blog-default-content">
								{post.blogField.content && (
									<SafeHtmlParser htmlContent={post.blogField.content} />
								)}
							</Col>
						</Row>
					</Container>
				</section>
				{post?.pageFieldsGroup?.components?.map((component, index) => {
					const renderComponent = componentMap[component.fieldGroupName];
					return renderComponent ? (
						<div key={index}>{renderComponent(component)}</div>
					) : null;
				})}
			</Layout>
		</>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query ($id: String!) {
		allWpTeam(sort: { order: ASC, fields: date }) {
			nodes {
				teamsFields {
					email
					fieldGroupName
					linkedinUrl
					location
					name
					phone
					role
					roleType
					twitterUrl
					description
					profileImage {
						altText
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [WEBP, AUTO]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									placeholder: BLURRED
									width: 80
								)
							}
						}
					}
				}
				title
			}
		}
		allWpPost(sort: { order: DESC, fields: date }) {
			nodes {
				title
				slug
				blogField {
					content
					excerpt
					readTime
					fieldGroupName
					featureImage {
						altText
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [WEBP, AUTO]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
					bannerBackgroundImage {
						altText
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [WEBP, AUTO]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}

		reviews {
			reviewCount
			reviews {
				author
				datePublished
				source
				reviewBody
			}
		}

		post: wpPost(id: { eq: $id }) {
			id
			slug
			title
			date(formatString: "DD MMMM, YYYY")
			author {
				node {
					id
					name
					avatar {
						url
					}
				}
			}
			blogField {
				content
				excerpt
				fieldGroupName
				readTime
				tagline
				featureImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				bannerBackgroundImage {
					altText
					sourceUrl
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: [WEBP, AUTO]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			terms {
				nodes {
					slug
					name
				}
			}
			seoFields {
				opengraphTitle
				opengraphDescription
				metaTitle
				metaDescription
				fieldGroupName
				productSchema
				image {
					altText
					sourceUrl
					mediaDetails {
						height
						width
					}
				}
			}
			pageFieldsGroup {
				pageSettings {
					defaultHeader
				}
				components {
					... on WpPost_Pagefieldsgroup_Components_Header1 {
						description
						fieldGroupName
						heading
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						link {
							target
							title
							url
						}
						logos {
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpPost_Pagefieldsgroup_Components_Header2 {
						fieldGroupName
						heading
						link {
							target
							title
							url
						}
						overlayColor
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPost_Pagefieldsgroup_Components_CustomerTestimonial {
						description
						fieldGroupName
						heading
						overlayColor
						testimonials {
							... on WpTestimonial {
								id
								title
							}
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPost_Pagefieldsgroup_Components_CardsLayout {
						fieldGroupName
						heading
						link {
							target
							title
							url
						}
						services {
							... on WpService {
								id
								slug
								serviceFields {
									description
									icon {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
													width: 64
												)
											}
										}
									}
									name
								}
							}
						}
					}
					... on WpPost_Pagefieldsgroup_Components_Image {
						fieldGroupName
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPost_Pagefieldsgroup_Components_CardsLayout2 {
						centerAlign
						description
						heading
						fieldGroupName
						items {
							description
							heading
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
											height: 64
										)
									}
								}
							}
						}
						link {
							target
							title
							url
						}
						learnMoreLink {
							target
							title
							url
						}
					}
					... on WpPost_Pagefieldsgroup_Components_BlogListing {
						description
						fieldGroupName
						heading
						tagline
					}
					... on WpPost_Pagefieldsgroup_Components_ContactLayout2 {
						description
						fieldGroupName
						heading
					}
					... on WpPost_Pagefieldsgroup_Components_ContactLayout {
						description
						fieldGroupName
						heading
						contactItem {
							fieldGroupName
							heading
							isEmergency
							subheading
							linkWithArrow
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
											height: 64
										)
									}
								}
							}
							link {
								target
								title
								url
							}
						}
					}
					... on WpPost_Pagefieldsgroup_Components_SectionLayout {
						description
						fieldGroupName
						heading
					}
					... on WpPost_Pagefieldsgroup_Components_SectionLayout2 {
						backgroundColor
						description
						fieldGroupName
						heading
						overlayColor
						tagline
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						link {
							target
							title
							url
						}
					}
					... on WpPost_Pagefieldsgroup_Components_SectionLayout3 {
						backgroundColor
						description
						fieldGroupName
						heading
						tagline
						link {
							target
							title
							url
						}
					}
					... on WpPost_Pagefieldsgroup_Components_SectionLayout4 {
						description
						fieldGroupName
						heading
						overlayColor
						items {
							fieldGroupName
							title
							description
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPost_Pagefieldsgroup_Components_OurTeam {
						fieldGroupName
						heading
						hiringDescription
						hiringTitle
						hiringLink {
							target
							title
							url
						}
					}
					... on WpPost_Pagefieldsgroup_Components_CtaBanner {
						backgroundColor
						description
						fieldGroupName
						heading
						icon {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
										height: 64
									)
								}
							}
						}
						link {
							target
							title
							url
						}
					}
					... on WpPost_Pagefieldsgroup_Components_BlogListingAll {
						description
						fieldGroupName
						heading
						tagline
					}
					... on WpPost_Pagefieldsgroup_Components_SectionLayout5 {
						fieldGroupName
						note
					}
					... on WpPost_Pagefieldsgroup_Components_SectionLayout3 {
						backgroundColor
						description
						fieldGroupName
						heading
						tagline
						link {
							target
							title
							url
						}
					}
					... on WpPost_Pagefieldsgroup_Components_RelatedBlogs {
						description
						fieldGroupName
						heading
						tagline
						blogs {
							... on WpPost {
								id
								title
								slug
								blogField {
									content
									excerpt
									readTime
									fieldGroupName
									featureImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
						link {
							target
							title
							url
						}
					}
					... on WpPost_Pagefieldsgroup_Components_CareerListing {
						fieldGroupName
						positions {
						  ... on WpPosition {
							id
							positionFields {
							  description
							  fieldGroupName
							  positionsInfo {
								title
							  }
							  positionShortDesc
							  positionDetail {
								content
								title
							  }
							  applyCta {
								target
								title
								url
							  }
							}
							title
						  }
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
