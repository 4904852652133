import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import SocialShare from "./SocialShare";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const BlogBanner = ({ title, postFields, postUrl, date, author }) => {
	const pluginImage = getImage(
		postFields?.bannerBackgroundImage?.localFile?.childImageSharp
			?.gatsbyImageData
	);

	const backgroundStyle = {
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		minHeight: "660px",
		marginBottom: "-1px",
		borderBottom: "1px solid #FFF",
	};

	const overlayStyle = {
		background:
			"linear-gradient(180deg, #064974 5.13%, rgba(6, 73, 116, 0.37) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)",
		backgroundBlendMode: "multiply",
	};

	return (
		<BgImage
			Tag="section"
			className="position-relative hero-wrapper overflow-hidden hero-home backround-wrapper"
			image={pluginImage}
			style={backgroundStyle}
		>
			<div
				className="position-absolute start-0 top-0 h-100 w-100"
				style={overlayStyle}
			></div>

			<Container
				className="py-6 pt-md-10 pb-md-8 position-relative"
				style={{ zIndex: 1 }}
			>
				<Row className="align-items-md-center justify-content-center">
					<Col xl={12} className="mb-4 mb-lg-0 mt-5">
						{postFields.tagline && (
							<span className="text-white mb-3 d-block text-center mb-3 sora-semibold">
								{postFields.tagline}
							</span>
						)}
						<h1 className="my-auto text-white text-center">{title}</h1>
						<Row className="mt-6 align-items-md-center">
							<Col md={8}>
								<div className="d-flex align-items-center">
									{/* {author.avatar.url ? (
                    <img src={author.avatar.url} className="rounded-circle" style={{ width: "48px", height: "48px" }}/>
                  ) : (
                    <StaticImage src="../images/placeholder.png" width={48} />
                  )} */}
									<div className="ms-3">
										<span className="raleway-semibold text-white mb-0">
											{author.name}
										</span>
										<div className="d-flex align-items-center">
											<span className="text-white mb-2 raleway-regular">
												{" "}
												{date}
											</span>
											{postFields.readTime && (
												<>
													<span
														className="px-2 text-white"
														style={{ marginTop: "-6px" }}
													>
														.
													</span>
													<span className="text-white mb-0 raleway-regular">
														{postFields.readTime} Read
													</span>
												</>
											)}
										</div>
									</div>
								</div>
							</Col>
							<Col md={4} className="text-md-end mt-4 mt-md-0">
								<SocialShare postTitle={title} postUrl={postUrl} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col className="pt-3 pt-md-5 pt-xl-7">
						<GatsbyImage
							image={
								postFields.featureImage?.localFile.childImageSharp
									.gatsbyImageData
							}
							alt={postFields.featureImage?.altText}
							className="rounded-5 banner-image w-100"
							style={{ maxHeight: "720px" }}
						/>
					</Col>
				</Row>
			</Container>
		</BgImage>
	);
};

export default BlogBanner;
